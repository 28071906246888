<template>
    <v-container fluid>
		<v-row justify="center" class="ma-0">
			<v-col cols="12" xs="12" md="8" class="pa-0" :class="!affichagePortable ? 'pl-8':''">
				
				<v-row align="center" class="mb-4 px-4 mt-4" :class="affichagePortable ? 'mt-8' : ''">
					<v-btn fab outlined small @click="mois_precedent" :loading="chargement_mois_precedent">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-slide-y-transition mode="out-in">
						<div class="flex-grow-1 text-center primary--text text-h5 font-weight-light" :key="titre_mois">{{ titre_mois }}</div>
					</v-slide-y-transition>
					<v-btn fab outlined small @click="mois_suivant" :loading="chargement_mois_suivant">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-row>
				<v-row class="ma-0">
					<v-calendar id="calendrier_dispos_gardes" :class="affichagePortable ? 'calendrier_portable':'calendrier_normal'" ref="calendar" type="month" 
						locale = 'fr'
						v-model="start.date" 
						:weekdays="jours_semaine" 
						@change="updateRange">
						<template v-slot:day="{day, date}" >
							<v-fade-transition mode="out-in">
								<v-row :key="planning_courant ? planning_courant.id : null" v-if="affichageJour(date)" class="ma-0" :class="affichagePortable ? 'zone_gardes_dispos_portable': 'zone_gardes_dispos_normal'">
									<v-card v-for="creneau in creneaux" :key="creneau.id" :width="(100/creneaux.length)+'%'" :max-width="(100/creneaux.length)+'%'" height="100%" class="pa-0" :color="couleurCard(date, creneau)" :disabled="disabledCard(date, creneau)" @click="onClickCreneau(date, creneau)" ripple flat tile>
										<v-col class="ma-0 pa-0" style="height: 100%">
											<v-row class="ma-0 pa-0" align="center" justify="center" style="height: 100%">
												<div class="font-weight-bold white--text">{{texteCard(date, creneau).texte}}</div>
											</v-row>
										</v-col>
									</v-card>
								</v-row>
							</v-fade-transition>
						</template>
						<template v-slot:day-label="{day, date}">
							<v-sheet class="py-1">
								<v-btn depressed small fab @click="onClickJour(date)">{{ day}}</v-btn>
							</v-sheet>
						</template>
					</v-calendar> 
				</v-row>
				<v-row class="ma-0">
					<v-fade-transition mode="out-in">
						<v-alert :key="message_statut_planning.message" border="left" :width="affichagePortable ? '100%' : ''" :dense="affichagePortable" :type="message_statut_planning.type" :class="affichagePortable ? 'mt-6':'mt-12'" v-if="message_statut_planning.message">
							{{ message_statut_planning.message }}
						</v-alert>
                    </v-fade-transition>
				</v-row>
			</v-col>
			<v-col md="4" class="pl-12" v-if="!affichagePortable">
				<v-row class="mx-0 mt-12">
					<v-fade-transition mode="out-in">
						<v-col v-if="affichage_zone_remplacants" class="pa-0" :key="affichage_zone_remplacants">
							<v-toolbar dark flat dense color="brown lighten-1">
                    			<v-toolbar-title>
									<v-slide-x-transition mode="out-in">
										<div :key="remplacement.date+'_'+remplacement.nom_creneau">{{remplacement.date+' ('+remplacement.nom_creneau+')'}}</div>
									</v-slide-x-transition>
								</v-toolbar-title>
                    		</v-toolbar>
							<v-fade-transition mode="out-in">
								<div :key="remplacement.date+'_'+remplacement.nom_creneau" class="liste_dispo_remplacant">
									<v-toolbar dense flat dark color="brown lighten-3" height="35">
										<v-toolbar-title class="font-weight-light text-body-2">SPV disponibles</v-toolbar-title>
										<v-spacer></v-spacer>
										<v-chip small color="brown lighten-4" class="brown--text text--darken-2 font-weight-bold">{{ remplacement.dispos.length }}</v-chip>
									</v-toolbar>
									<v-row class="ma-0">
										<v-list v-if="remplacement.dispos.length > 0" class="pa-0" width="100%" dense>
											<template v-for="(disponible, index) in remplacement.dispos">
												<v-list-item class="px-2" :key="disponible.id">
													<v-list-item-avatar color="brown lighten-3" size="30">
														<img v-if="disponible.membre.user.photo" :src="getPhoto(disponible.membre.user.photo)">
														<span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(disponible.membre.user.prenom)+premiereLettreMaj(disponible.membre.user.nom)}}</span>
													</v-list-item-avatar>

													<v-list-item-content>
														<v-list-item-title>{{ disponible.membre.user.grade.nom + ' ' + disponible.membre.user.nom + ' ' +  disponible.membre.user.prenom}}</v-list-item-title>
													</v-list-item-content>
													<v-list-item-action>
														<v-tooltip left v-if="acces_autorise('modifications planning gardes centre') && !estDatePassee(remplacement.date_moment)">
															<template v-slot:activator="{ on }">
																<v-btn icon v-on="on" color="light-green darken-1" @click="onClickAttribuerGarde(disponible)"><v-icon>mdi-plus-circle</v-icon></v-btn>
															</template>
															<span>Attribuer la garde</span>
														</v-tooltip>
													</v-list-item-action>
												</v-list-item>
												<v-divider light v-if="index + 1 < remplacement.dispos.length" :key="'divider'+index"></v-divider>
											</template>
										</v-list>
										<v-row class="mx-0 mt-4 mb-4" v-else justify="center">
											<div class="font-italic">Aucun SPV disponible</div>
										</v-row>
									</v-row>
									<v-toolbar dense flat dark class="mt-4" color="brown lighten-3" height="35">
										<v-toolbar-title class="font-weight-light text-body-2">SPV de garde</v-toolbar-title>
										<v-spacer></v-spacer>
										<v-chip small color="brown lighten-4" class="brown--text text--darken-2 font-weight-bold">{{ remplacement.gardes.length }}</v-chip>
									</v-toolbar>
									<v-row class="ma-0">
										<v-list v-if="remplacement.gardes.length > 0" class="pa-0" width="100%" dense>
												<template v-for="(degarde, index) in remplacement.gardes">
													<v-list-item class="px-2" :key="degarde.id">
														<v-list-item-avatar color="brown lighten-3" size="30">
															<img v-if="degarde.membre.user.photo" :src="getPhoto(degarde.membre.user.photo)">
															<span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(degarde.membre.user.prenom)+premiereLettreMaj(degarde.membre.user.nom)}}</span>
														</v-list-item-avatar>

														<v-list-item-content>
															<v-list-item-title>{{ degarde.membre.user.grade.nom + ' ' + degarde.membre.user.nom + ' ' +  degarde.membre.user.prenom}}</v-list-item-title>
														</v-list-item-content>
														<v-list-item-action>
															<v-tooltip left v-if="acces_autorise('modifications planning gardes centre') && !estDatePassee(remplacement.date_moment)">
																<template v-slot:activator="{ on }">
																	<v-btn icon v-on="on" color="red darken-1" @click="onClickAnnulerGarde(degarde)"><v-icon>mdi-minus-circle</v-icon></v-btn>
																</template>
																<span>Annuler la garde</span>
															</v-tooltip>
														</v-list-item-action>
													</v-list-item>
													<v-divider light v-if="index + 1 < remplacement.gardes.length" :key="'divider'+index"></v-divider>
												</template>
											</v-list>
										<v-row class="mx-0 mt-4" v-else justify="center">
											<div class="font-italic">Aucun SPV de garde</div>
										</v-row>
									</v-row>
								</div>
							</v-fade-transition>
						</v-col>
					</v-fade-transition>
				</v-row> 
			</v-col>
		</v-row>

		<!-- dialogue detail jour -->
        <v-dialog v-model="openDialogueDetailJour" max-width="400" @click:outside="annuleDetailJour">
            <v-card>
				<v-row align="center" class="ma-0 pa-3">
					<v-btn fab outlined x-small @click="detail_jour_precedent" :disabled=" start != null && formulaireDetailJour.jour == start.date">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-fade-transition mode="out-in">
						<div class="flex-grow-1 text-center title" :key="formulaireDetailJour.titre">{{ formulaireDetailJour.titre }}</div>
					</v-fade-transition>
					<v-btn fab outlined x-small @click="detail_jour_suivant" :disabled=" end != null && formulaireDetailJour.jour == end.date">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-row>
                <v-card-text>
					<v-row class="ma-0">
						<v-col class="pa-0">
							<v-fade-transition mode="out-in">
								<v-row :key="formulaireDetailJour.jour" class="ma-0">
									<v-card v-for="creneau in creneaux" :key="creneau.id" outlined :width="(100/creneaux.length)+'%'" :max-width="(100/creneaux.length)+'%'" height="85" class="pa-0" :disabled="disabledCard(formulaireDetailJour.jour, creneau)" @click="onClickCreneau(formulaireDetailJour.jour, creneau)" ripple flat tile>
										<v-card-text>
											<div class="text-center mb-1 font-weight-bold">{{affichageHeure(creneau.heure_debut)}}<v-icon class="mx-1">mdi-arrow-right-thick</v-icon>{{affichageHeure(creneau.heure_fin)}}</div>
											<div class="text-center font-italic text-caption" v-if="texteCard(formulaireDetailJour.jour, creneau).texte == ''">Aucun SPV disponible</div>
											<div v-else class="text-center"><v-chip color="brown lighten-4" class="brown--text text--darken-2 font-weight-bold">{{ texteCard(formulaireDetailJour.jour, creneau).texte }}</v-chip></div>
										</v-card-text>
									</v-card>
								</v-row>
							</v-fade-transition>
							<v-expand-transition>
								<v-row v-if="affichage_zone_remplacants_portable" class="ma-0 mt-2">
									<v-col class="pa-0">
										<v-toolbar dense flat dark color="brown lighten-3" height="35">
											<v-toolbar-title class="font-weight-light text-body-2">SPV disponibles ({{remplacement.nom_creneau}})</v-toolbar-title>
											<v-spacer></v-spacer>
											<v-chip small color="brown lighten-4" class="brown--text text--darken-2 font-weight-bold">{{ remplacement.dispos.length }}</v-chip>
										</v-toolbar>
										<v-row class="ma-0">
											<v-list v-if="remplacement.dispos.length > 0" class="pa-0" width="100%" dense>
												<template v-for="(disponible, index) in remplacement.dispos">
													<v-list-item class="px-1" :key="disponible.id">
														<v-list-item-avatar color="brown lighten-3" size="30">
															<img v-if="disponible.membre.user.photo" :src="getPhoto(disponible.membre.user.photo)">
															<span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(disponible.membre.user.prenom)+premiereLettreMaj(disponible.membre.user.nom)}}</span>
														</v-list-item-avatar>

														<v-list-item-content>
															<v-list-item-title>{{ disponible.membre.user.grade.nom + ' ' + disponible.membre.user.nom + ' ' +  disponible.membre.user.prenom}}</v-list-item-title>
														</v-list-item-content>
														<v-list-item-action>
															<v-tooltip left v-if="acces_autorise('modifications planning gardes centre') && !estDatePassee(remplacement.date_moment)">
																<template v-slot:activator="{ on }">
																	<v-btn icon v-on="on" color="light-green darken-1" @click="onClickAttribuerGarde(disponible)"><v-icon>mdi-plus-circle</v-icon></v-btn>
																</template>
																<span>Attribuer la garde</span>
															</v-tooltip>
														</v-list-item-action>
													</v-list-item>
													<v-divider light v-if="index + 1 < remplacement.dispos.length" :key="'divider'+index"></v-divider>
												</template>
											</v-list>
											<v-row class="ma-0 mt-4 mb-4" v-else justify="center">
												<div class="font-italic">Aucun SPV disponible</div>
											</v-row>
										</v-row>
										<v-toolbar dense flat dark class="mt-4" color="brown lighten-3" height="35">
											<v-toolbar-title class="font-weight-light text-body-2">SPV de garde ({{remplacement.nom_creneau}})</v-toolbar-title>
											<v-spacer></v-spacer>
											<v-chip small color="brown lighten-4" class="brown--text text--darken-2 font-weight-bold">{{ remplacement.gardes.length }}</v-chip>
										</v-toolbar>
										<v-row class="ma-0">
											<v-list v-if="remplacement.gardes.length > 0" class="pa-0" width="100%" dense>
												<template v-for="(degarde, index) in remplacement.gardes">
													<v-list-item class="px-1" :key="degarde.id">
														<v-list-item-avatar color="brown lighten-3" size="30">
															<img v-if="degarde.membre.user.photo" :src="getPhoto(degarde.membre.user.photo)">
															<span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(degarde.membre.user.prenom)+premiereLettreMaj(degarde.membre.user.nom)}}</span>
														</v-list-item-avatar>

														<v-list-item-content>
															<v-list-item-title>{{ degarde.membre.user.grade.nom + ' ' + degarde.membre.user.nom + ' ' +  degarde.membre.user.prenom}}</v-list-item-title>
														</v-list-item-content>
														<v-list-item-action>
															<v-tooltip left v-if="acces_autorise('modifications planning gardes centre') && !estDatePassee(remplacement.date_moment)">
																<template v-slot:activator="{ on }">
																	<v-btn icon v-on="on" color="red darken-1" @click="onClickAnnulerGarde(degarde)"><v-icon>mdi-minus-circle</v-icon></v-btn>
																</template>
																<span>Annuler la garde</span>
															</v-tooltip>
														</v-list-item-action>
													</v-list-item>
													<v-divider light v-if="index + 1 < remplacement.gardes.length" :key="'divider'+index"></v-divider>
												</template>
											</v-list>
											<v-row class="ma-0 mt-4" v-else justify="center">
												<div class="font-italic">Aucun SPV de garde</div>
											</v-row>
										</v-row>
									</v-col>
								</v-row>
							</v-expand-transition>
						</v-col>
					</v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

		<!-- dialogue confirmation -->
        <v-dialog v-model="openDialogueConfirmation" max-width="290" persistent>
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>{{ dialogue_confirmation.titre }}</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">{{ dialogue_confirmation.texte }}</v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="blue-grey darken-1" text @click="annuleConfirmation">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="validConfirmation" :loading="chargement_confirmation">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 
	</v-container>
</template>

<script>
	import axios from 'axios'
	import Moment from 'moment'
	Moment.locale('fr')
	import { extendMoment } from 'moment-range'
	const moment = extendMoment(Moment)
    export default {
        created() {
			this.$store.commit('majTitrePage', 'Disponibilités du centre')
        },
        mounted() {
			this.$refs.calendar.checkChange()
        },
        data() {
			return {
				affichage_zone_remplacants: false,
				affichage_zone_remplacants_portable: false,
				autorisation_sauvegarde_dispos: true,
				chargement_confirmation: false,
				chargement_mois_precedent: false,
				chargement_mois_suivant: false,
				chargement_planning: false,
				chargement_remplacants: false,
				chargement_sauvegarde: false,
				dialogue_confirmation: {
					titre: '',
					texte: '',
					id_element: null,
					id_destinataire: null,
					action: '',
					personne_concernee: ''
				},
				formulaireDetailJour: {
					jour: null,
					message_atreinte: '',
					titre: ''
				},
				gardes_dispos: [],
				message_statut_planning: {
					type: null,
					message: ''
				},
				end: null,
				jours_semaine: [1,2,3,4,5,6,0],
				openDialogueConfirmation: false,
				openDialogueDetailJour: false,
				planning_courant: null,
				remplacement: {
					date: null,
					id_creneau: null,
					nom_creneau: null,
					dispos: [],
					gardes: [],
					date_moment: null
				},
				start: '2019-01-01'	// date aléatoire initiale
			}
		},
		computed: {
			affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
			centre_courant() {
				return this.$store.getters.membreCourant.centre
			},
			evenements() {
				return []
			},
			monthFormatter () {
				return this.$refs.calendar.getFormatter({
					timeZone: 'UTC', month: 'long',
				})
			},
			creneaux() {
				return this.$store.getters.membreCourant.centre.periodes
			},
			titre_mois() {
				const { start, end } = this
				if (!start || !end) {
					return ''
				}
				const startMonth = this.monthFormatter(start)
				const startYear = start.year
				return `${startMonth} ${startYear}`
			}
		},
		methods: {
			acces_autorise(permissions_requises) {
                // retourne true si l'utilisateur à les permissions listées
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
			affichageHeure(heure) {
				// transforem HH:mm:ss en Hhmm
				return moment(heure, 'HH:mm:ss').format('H:mm')
			},
			affichageJour(date) {
				// renvoie true si le jour est entre date debut et date fin
				if(!this.start || !this.end) return false
				return moment(date).isBetween(this.start.date, this.end.date, null, '[]')
			},
			annuleConfirmation() {
				this.dialogue_confirmation.titre = ''
                this.dialogue_confirmation.texte = ''
				this.dialogue_confirmation.action = ''
				this.dialogue_confirmation.id_element = null
				this.dialogue_confirmation.id_destinataire = null
				this.dialogue_confirmation.personne_concernee = ''
                this.openDialogueConfirmation = false
			},
			annuleDetailJour() {
				this.openDialogueDetailJour = false
				this.formulaireDetailJour.titre = ''
				this.formulaireDetailJour.jour = null
			},
			chargerDetailJour(date) {
				this.affichage_zone_remplacants_portable = false
				this.formulaireDetailJour.jour = date
				this.formulaireDetailJour.titre = moment(date).format('dddd DD/MM/YYYY')
			},
			couleurCard(date, creneau) {
				const jour = this.gardes_dispos.find( jour => jour.jour == date)
				if(jour) {
					const periode = jour.creneaux.find( elem => elem.id_creneau == creneau.id)
					
					//	 on ne met une couleur que pour les créneaux avec des membres dispos
					if(periode && periode.dispos.length > 0) {
						return 'brown lighten-4'
					}
				}
				return ''
			},
			detail_jour_precedent() {
				this.chargerDetailJour(moment(this.formulaireDetailJour.jour).subtract(1, 'day').format('YYYY-MM-DD'))
			},
			detail_jour_suivant() {
				this.chargerDetailJour(moment(this.formulaireDetailJour.jour).add(1, 'day').format('YYYY-MM-DD'))
			},
			disabledCard(date, creneau) {
				//	si le planning n'est pas diffusé on désactive
				if(this.planning_courant && this.planning_courant.id_statut == 3) return false
				return true
			},
			estDatePassee(date) {
				//	renvoie true si la date est passée (au foramt YYYY-MM-DD)
				return moment(date).isBefore(moment(), 'day')
			},
			fetchDisposGardes(post_sauvegarde = null) {
				this.chargement_planning = true
				axios.post('api/planning/dispos_centre', { id_membre:this.$store.getters.membreCourant.id, id_centre: this.$store.getters.membreCourant.centre.id ,date_debut: this.start.date, date_fin: this.end.date })
                    .then( (reponse) => {
						this.planning_courant = reponse.data.planning
						this.initialiserGardesDispos()
						
						//	si un planning est renvoyé
						if(reponse.data.planning) {
							if(reponse.data.planning.id_statut != 3) {
								this.message_statut_planning = {
									type: 'info',
									message: 'Le planning n\'est pas encore réalisé'
								}
							}
							else {
								this.message_statut_planning = {
									type: null,
									message: ''
								}
							}
						}
						else {
							this.message_statut_planning = {
								type: null,
								message: ''
							}
						}
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors du chargement des dispos du centre');
                    })
                    .then(() => {
						this.chargement_planning = false
						this.chargement_mois_suivant = false
						this.chargement_mois_precedent = false
                    })
			},
			getPhoto(chemin){
				return "/storage/"+chemin;
			},
			initialiserGardesDispos() {
				let planning = []
				
				
				//	initialisation du nombre de creneau par jour à utiliser
				const creneaux_jour = []
				this.creneaux.forEach(creneau => {
					creneaux_jour.push({
						id_creneau: creneau.id,
						nom_creneau: creneau.nom,
						gardes: [],
						dispos: [],
						//id_garde: null
					})
				})

				if(this.start && this.end) {
					
					//	initialisation avec un planning avec aucune garde ni dispo
					const range = moment.range(moment(this.start.date), moment(this.end.date))
					for (let jour of range.by('day')) {
						planning.push({
							jour: jour.format('YYYY-MM-DD'),
							creneaux: JSON.parse(JSON.stringify(creneaux_jour))		// technique pour faire une 'deep copie' de creneaux_jour sinon on ne copie que des references et tous les objets pointent sur le même creneaux_jour
						})
					}
					
					//	ajout des dispos et gardes enregistrées
					if(this.planning_courant && this.planning_courant.plannifications.length > 0) {
						
						//	on parcourt chaque planning de membre
						this.planning_courant.plannifications.forEach(plannif => {

							plannif.gardes.forEach( gardedispo => {
								// format de gardedispo:
								// date: '2019-12-04
								// dispo: true/false
								// garde: true/false
								// id_periode: 1
								// id

								const indexPlanning = planning.findIndex( element => element.jour == gardedispo.date)
								//	le jour existe dans le tableau
								if(indexPlanning >= 0) {
									const indexCreneau = planning[indexPlanning].creneaux.findIndex( elem => elem.id_creneau == gardedispo.id_periode)
									//	si le créneau est trouvé
									if(indexCreneau >= 0){
										// si le membre est dispo et non de garde on l'ajoute aux tableaux des dispos
										if(gardedispo.dispo && ! gardedispo.garde) {
											planning[indexPlanning].creneaux[indexCreneau].dispos.push({
												membre: plannif.membre,
												id_plannification: plannif.id
											})
										}
										else if(gardedispo.garde) {
											planning[indexPlanning].creneaux[indexCreneau].gardes.push({
												membre: plannif.membre,
												id_garde: gardedispo.id
											})
										}
									}
								}
							})
						})
						 	
					}
				}
				this.gardes_dispos = planning
			},
			mois_precedent() {
				this.chargement_mois_precedent = true
				this.$refs.calendar.prev()
				this.affichage_zone_remplacants = false
			},
			mois_suivant() {
				this.chargement_mois_suivant = true
				this.$refs.calendar.next()
				this.affichage_zone_remplacants = false
			},
			onClickAnnulerGarde(degarde) {
				this.dialogue_confirmation.titre = 'Annulation'
				this.dialogue_confirmation.texte = 'Annuler la garde de '+degarde.membre.user.prenom+' '+degarde.membre.user.nom+' du '+this.remplacement.date+' de '+this.remplacement.nom_creneau+' ?'
				this.dialogue_confirmation.action = 'annulation'
				this.dialogue_confirmation.id_element = degarde.id_garde
				this.dialogue_confirmation.personne_concernee = degarde.membre.user.prenom + ' ' + degarde.membre.user.nom
				this.dialogue_confirmation.id_destinataire = degarde.membre.user.id
				this.openDialogueConfirmation = true
			},
			onClickAttribuerGarde(dispo) {
				this.dialogue_confirmation.titre = 'Attribution'
				this.dialogue_confirmation.texte = 'Attribuer la garde du '+this.remplacement.date+' de '+this.remplacement.nom_creneau+' à '+dispo.membre.user.prenom+' '+dispo.membre.user.nom+ ' ?'
				this.dialogue_confirmation.action = 'attribution'
				this.dialogue_confirmation.id_element = dispo.id_plannification
				this.dialogue_confirmation.personne_concernee = dispo.membre.user.prenom + ' ' + dispo.membre.user.nom
				this.dialogue_confirmation.id_destinataire = dispo.membre.user.id
				this.openDialogueConfirmation = true
			},
			onClickCreneau(date, creneau) {
				this.chargement_remplacants = true
				const indexPlanning = this.gardes_dispos.findIndex( element => element.jour == date)
				if(indexPlanning >= 0) {
					const indexCreneau = this.gardes_dispos[indexPlanning].creneaux.findIndex( elem => elem.id_creneau == creneau.id)
					//	si le créneau est trouvé
					if(indexCreneau >= 0){
						const jourGarde = this.gardes_dispos[indexPlanning]
						
						this.remplacement.date = moment(jourGarde.jour).format('dddd DD/MM/YYYY')
						this.remplacement.date_moment = jourGarde.jour
						this.remplacement.nom_creneau = this.gardes_dispos[indexPlanning].creneaux[indexCreneau].nom_creneau.toLowerCase()
						this.remplacement.id_creneau = this.gardes_dispos[indexPlanning].creneaux[indexCreneau].id_creneau
							
						//	 on réorganise les remplacants par ordre alphabétique de l'utilisateur
						this.remplacement.dispos = this.gardes_dispos[indexPlanning].creneaux[indexCreneau].dispos.length > 0 ? this.gardes_dispos[indexPlanning].creneaux[indexCreneau].dispos.sort((a,b) => {
								if(a.membre.user.nom.toLowerCase() < b.membre.user.nom.toLowerCase()) {
									return -1
								}
								else if(a.membre.user.nom.toLowerCase() > b.membre.user.nom.toLowerCase()) {
									return 1
								}

								//	ici on a le même nom donc on tri par prénom
								if(a.membre.user.prenom.toLowerCase() < b.membre.user.prenom.toLowerCase()) {
									return -1
								}
								else if(a.membre.user.prenom.toLowerCase() > b.membre.user.prenom.toLowerCase()) {
									return 1
								}
								return 0
						}) : []
						this.remplacement.gardes = this.gardes_dispos[indexPlanning].creneaux[indexCreneau].gardes.length > 0 ? this.gardes_dispos[indexPlanning].creneaux[indexCreneau].gardes.sort((a,b) => {
								if(a.membre.user.nom.toLowerCase() < b.membre.user.nom.toLowerCase()) {
									return -1
								}
								else if(a.membre.user.nom.toLowerCase() > b.membre.user.nom.toLowerCase()) {
									return 1
								}

								//	ici on a le même nom donc on tri par prénom
								if(a.membre.user.prenom.toLowerCase() < b.membre.user.prenom.toLowerCase()) {
									return -1
								}
								else if(a.membre.user.prenom.toLowerCase() > b.membre.user.prenom.toLowerCase()) {
									return 1
								}
								return 0
						}) : []
							
							
						this.affichage_zone_remplacants = true

						

							//	en mode portable
							if(this.affichagePortable) {
								// si la boite de dialogue detail n'est pas ouverte on l'ouvre
								if(!this.openDialogueDetailJour) {
									this.onClickJour(date)
								}
								else {
									this.affichage_zone_remplacants_portable = true
								}
							}								
						//}
					}
				}
				this.chargement_remplacants = false
			},
			onClickJour(date) {
				if(this.affichagePortable) {
					this.chargerDetailJour(date)
					this.openDialogueDetailJour = true					
				}
			},
			premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
			},
			snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
			},
			texteCard(date, creneau) {
				// retourne le texte à afficher dans la case de couleur dispo/garde et le type (icone ou texte)
				const jour = this.gardes_dispos.find( jour => jour.jour == date)
				if(jour) {
					const periode = jour.creneaux.find( elem => elem.id_creneau == creneau.id)
					if(periode && periode.dispos.length > 0) {
						return {
							texte: periode.dispos.length,
							type: 'texte'
						}
					}
					
				}
				
				//	sinon on ne renvoie rien
				return {
					texte: '',
					type: 'texte'
				}
			},
			updateRange ({ start, end }) {
				// You could load events from an outside source (like database) now that we have the start and end dates on the calendar
				// on met à jour les date de début et fin de mois
				this.start = start
				this.end = end
				this.fetchDisposGardes()
				
			},
			validConfirmation() {
				this.chargement_confirmation = true
				let chemin = ''
				let message_erreur = ''
				let message_ok = ''
				switch (this.dialogue_confirmation.action) {
					case 'attribution':
						chemin = 'api/planning/attribuer_garde'
						message_erreur = 'Erreur lors de l\'attribution de la garde'
						break
					case 'annulation':
						chemin = 'api/planning/annuler_garde'
						message_erreur = 'Erreur lors de l\'annulation de la garde'
						break
					default:
						break
				}

				axios.post(chemin, {
					id_planning: this.planning_courant.id,
					mois_planning: this.titre_mois,
					id_garde: this.dialogue_confirmation.id_element,	//	dans le cas d'une annulation
					id_membre: this.$store.getters.membreCourant.id,
					nom_destinataire: this.dialogue_confirmation.personne_concernee,
					id_plannification: this.dialogue_confirmation.id_element,	// dans le cas d'une attribution
					date: this.remplacement.date_moment,
					id_creneau: this.remplacement.id_creneau,
					nom_creneau: this.remplacement.nom_creneau,
					id_destinataire: this.dialogue_confirmation.id_destinataire
				})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                        this.fetchDisposGardes()
                     })
                    .catch((error) => {
                        this.snackbar('error', message_erreur);
                    })
                    .then(() => {
						this.chargement_confirmation = false
						this.affichage_zone_remplacants = false,
						this.affichage_zone_remplacants_portable = false,
						this.annuleConfirmation()
                    })
			}

        },
        validations: {
            
		},
		watch: {
			centre_courant() {
				
				//  si pas autorisé à rester sur cette page on le vire
                if(!this.acces_autorise('acces dispos centre')) {
					this.$router.push('/')
				}
				else {
					this.fetchDisposGardes()
				}
			}
		}
  	}
</script>

<style scoped>

.liste_dispo_remplacant {
	max-height: calc(100vh - 230px);
	min-height: calc(100vh - 230px);
    overflow-y: auto;
    overflow-x: hidden;
    
}

.message_astreinte_detail {
	min-height: 24px;
}

</style>

<style>
.bouton_dispos {
	color:#7E57C2 !important;
}

.bouton_gardes {
	color:#7CB342 !important;
}

.case_echange {
	border: 5px solid #C5E1A5 !important; 
}


#calendrier_dispos_gardes .v-calendar-weekly__head-weekday {
	background-color: #FAFAFA;
	padding-bottom: 5px;
	border-right: none;
	color: #757575;
	margin: 0;
}

#calendrier_dispos_gardes {
	border: 0 !important;
}




#calendrier_dispos_gardes .v-outside {
	background-color: #FAFAFA;
	border-right: none;
	border-bottom: none;
	
}

/* #calendrier_dispos_gardes .v-outside div{
	color: #FAFAFA;
} */

#calendrier_dispos_gardes .v-calendar-weekly__week .v-outside .v-calendar-weekly__day-label{
	display: none
}

#calendrier_dispos_gardes .v-calendar-weekly__day-label{
	margin: 0;
}

#calendrier_dispos_gardes .v-future .v-calendar-weekly__day-label button, #calendrier_dispos_gardes .v-past .v-calendar-weekly__day-label button{
	background-color: transparent;
}

/* #calendrier_dispos_gardes .v-present div{
	color: #C2185B;
} */

.calendrier_portable .v-calendar-weekly__week {
	min-height: 80px;
}

.calendrier_normal .v-calendar-weekly__week {
	min-height: 95px;
}

.textCardJour {
	height: 100%;
	text-align: center;
	
}

.zone_gardes_dispos_normal {
	height: calc(50% - 2px);
}

.zone_gardes_dispos_portable {
	height: calc(50% - 10px);
}

.zone_jour {
	min-height: 50px !important;
}



</style>